import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';

const backendApiUrl = process.env.REACT_APP_API_URL

message.config({
  top: 80
})

const Login = ({ handleLogin }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const response = await axios.post(`${backendApiUrl}/login`, {
        username: values.username,
        password: values.password,
      });

      if (response.status === 200) {
        console.log('Kirjautuminen onnistui:', response.data.token);
        // Tallenna token Local Storageen
        localStorage.setItem('jwtToken', response.data.token);
        // Tässä voit toteuttaa ohjauksen eteenpäin kirjautuneille käyttäjille
        handleLogin(response.data.token)
      }
    } catch (error) {
      console.error('Kirjautuminen epäonnistui:', error);
      message.error('Kirjautuminen epäonnistui');
    }

    setLoading(false);
  };


  return (
    <div className="login-container">
      <Form
        name="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Syötä käyttäjänimi!' }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Käyttäjänimi"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Syötä salasana!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Salasana"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            Kirjaudu sisään
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
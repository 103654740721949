import React, { useState, useEffect } from 'react';
import { Input, Button, Table, Spin, Descriptions, Modal, Layout, Menu, Row, Col, Typography, Upload, Form, Checkbox, Switch, Radio, Select, Popconfirm } from 'antd';
import axios from 'axios';
import Login from './Login';
import { message, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { parseISO, format } from 'date-fns'
import { DatePicker, InputNumber } from 'antd';

import {
  FileSearchOutlined,
  LogoutOutlined,
  DownloadOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

import jwtDecode from 'jwt-decode';

import './App.css';
const { Option } = Select;
const { TextArea } = Input
const { Header, Content } = Layout;
const { Title } = Typography;

const backendApiUrl = process.env.REACT_APP_API_URL

message.config({
  top: 80
})


function TilitysTapahtumat() {
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [editingRow, setEditingRow] = useState(null);
  const [form] = Form.useForm();  // Lisätään form-hook käyttöön
  const [filterApproved, setFilterApproved] = useState('false');
  const [filterOwn, setFilterOwn] = useState('0');
  const [filterGroup, setFilterGroup] = useState('all')

  const fetchData = async () => {
    try {
      const result = await axios.get(`${backendApiUrl}/api/tilitys-tapahtumat`)
      setData(result.data);
      setDataSource(result.data)
      console.log(result.data)
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Voit asettaa virheenkäsittelyn tässä esim. näyttää virheviestin
    }
  };

  // Datan lataaminen API:sta
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let filteredData = data;
    if (filterApproved !== 'all') {
      filteredData = filteredData.filter(item => (item.approved ? item.approved.toString() : 'false') === filterApproved);
    }
    if (filterOwn !== 'all') {
      filteredData = filteredData.filter(item => item.own === filterOwn);
    }

    if (filterGroup !== 'all') {
      filteredData = filteredData.filter(item => item.group === filterGroup);
    }
    setDataSource(filteredData);
  }, [filterApproved, filterOwn, filterGroup, data])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setDataSource(prevData =>
      prevData.filter(item =>
        item[dataIndex].toString().toLowerCase().includes(selectedKeys[0].toLowerCase())
      )
    );
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
    fetchData(); // Voit kutsua fetchData uudelleen palauttaaksesi alkuperäisen datasetin
  };

  const handleEdit = (record) => {
    form.setFieldsValue(record)
    setEditingRow({ ...record });
  };

  const handleApprove = async (id) => {
    try {
      const rowD = dataSource.find(r => r.id === id)
      const result = await axios.post(`${backendApiUrl}/api/tilitys-tapahtumat/approved/${id}`, {
        approved: !rowD.approved
      })
      console.log(result)
    } catch (error) {
      console.error('Error updating approval status:', error);
    }
    setDataSource(current =>
      current.map(row =>
        row.id === id ? { ...row, approved: !row.approved } : row
      )
    )
    setData(current =>
      current.map(row =>
        row.id === id ? { ...row, approved: !row.approved } : row
      )
    );
  };

  const handleDelete = (id) => {

    setDataSource(current =>
      current.filter(row => row.id !== id)
    )
    setData(current =>
      current.filter(row => row.id !== id)
    )
  };

  const saveEdit = async(values) => {


    try {
      let sum_total
      if ((editingRow?.type || '') === 'CAPITAL') {
        sum_total = (Number(values.sum_capital) + Number(values.sum_interest)).toFixed(2).toString()
      }
  
      if ((editingRow?.type || '') === 'FEE') {
        sum_total = Number(values.sum_fee).toFixed(2).toString()
      }
  
      const result = await axios.post(`${backendApiUrl}/api/tilitys-tapahtumat/${editingRow.id}`, {
        sum_total: sum_total,
        ...values
      })

      setDataSource(current =>
        current.map(row =>
          row.id === editingRow.id ? { ...row, ...values, sum_total: sum_total, updated_at: new Date() } : row
        )
      )
      setData(current =>
        current.map(row =>
          row.id === editingRow.id ? { ...row, ...values, sum_total: sum_total, updated_at: new Date() } : row
        )
      )

    } catch (error) {
      console.error(error)
    }

    form.resetFields()
    setEditingRow(null)
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Hae ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <button
          type="button"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: '100%' }}
        >
          Hae
        </button>
        <button
          onClick={() => handleReset(clearFilters)}
          style={{ width: '100%', marginTop: 8 }}
        >
          Nollaa
        </button>
      </div>
    ),
    filterIcon: filtered => <i className="anticon" style={{ color: filtered ? '#1890ff' : undefined }}>🔍</i>,
  });


  const handleOwnToggle = checked => {
    setFilterOwn(checked ? '1' : '0');
    // Suodata data sen perusteella, mikä on 'own' -arvo
  };

  const columns = [
    {
      title: 'Asiakasnumero',
      dataIndex: 'customer_number',
      ...getColumnSearchProps('customer_number'),
    },
    {
      title: 'Nimi',
      dataIndex: 'customer_name',
    },
    {
      title: 'Laskun numero',
      dataIndex: 'invoice_number',
      ...getColumnSearchProps('invoice_number'),
    },
    {
      title: 'Viitenumero (netvisor)',
      dataIndex: 'ref_netvisor',
      ...getColumnSearchProps('ref_netvisor'),
    },
    {
      title: 'Viite (TB)',
      dataIndex: 'ref',
      ...getColumnSearchProps('ref'),
    },
    {
      title: 'Yhteensä',
      dataIndex: 'sum_total',
    },
    {
      title: 'Pääoma',
      dataIndex: 'sum_capital',
    },
    {
      title: 'Korko',
      dataIndex: 'sum_interest',
    },
    {
      title: 'Kulut',
      dataIndex: 'sum_fee',
    },
    {
      title: 'Kohde',
      dataIndex: 'own',
      render: text => (text === '0' ? 'TB' : 'IJ'),
      filterDropdown: () => (
        <Switch
          checkedChildren="IJ"
          unCheckedChildren="TB"
          onChange={handleOwnToggle}
        />
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) =>
        <div>
          <Button type="link" onClick={() => handleEdit(record)} disabled={record.approved}>
            Muokkaa
          </Button>
          <Button type="link" onClick={() => handleApprove(record.id)}>
            {record.approved ? 'Peruuta hyväksyntä' : 'Hyväksy'}
          </Button>

        </div>
    }
  ];

  /*
            <Popconfirm
            title="Are you sure you want to delete this row?"
            onConfirm={() => handleDelete(record.id)}
            onCancel={() => console.log('Delete cancelled')}
            okText="Yes"
            cancelText="No"
            disabled={record.approved}
          >
            <Button type="danger" style={{ marginLeft: 8 }}>Poista</Button>
          </Popconfirm>
   */
  return (
    <div>
      <Select value={filterApproved} defaultValue="false" onChange={setFilterApproved} style={{ width: 120, marginRight: 8 }}>
        <Option value="all">Kaikki</Option>
        <Option value="true">Hyväksytyt</Option>
        <Option value="false">Ei hyväksytyt</Option>
      </Select>
      <Select value={filterOwn} defaultValue="0" onChange={setFilterOwn} style={{ width: 120 }}>
        <Option value="all">Molemmat</Option>
        <Option value="0">TB</Option>
        <Option value="1">IJ</Option>
      </Select>
      <Select value={filterGroup} onChange={setFilterGroup} style={{ width: 120 }}>
        <Option value="all">Kaikki</Option>
        {Array.from({ length: 5 }, (_, i) => (
          <Option key={i + 1} value={String(i + 1)}>{`${i + 1}`}</Option>
        ))}
      </Select>
      <Table columns={columns} dataSource={dataSource} size='small' pagination={ {defaultPageSize: '25'}}/>
      <Modal
        title="Edit Row"
        maskClosable={false}
        open={!!editingRow}
        onCancel={() => {
          form.resetFields()
          setEditingRow(null)
        }}
        onOk={() => {
          form.submit()
        }}
        destroyOnClose={true}
      >
        <Form
          form={form}
          onFinish={saveEdit}
          layout="vertical"
        >
          <Form.Item label="Customer Name" name="customer_name" >
            <Input disabled />
          </Form.Item>
          <Form.Item label="Pääoma" name="sum_capital">
            <InputNumber
              min="0"
              max="100000"
              step="0.01"
              stringMode
              precision="2"
              disabled={(editingRow?.type || '') === 'FEE'}
            />
          </Form.Item>
          <Form.Item label="Korko" name="sum_interest">
            <InputNumber
              min="0"
              max="100000"
              step="0.01"
              stringMode
              precision="2"
              disabled={(editingRow?.type || '') === 'FEE'}
            />
          </Form.Item>
          <Form.Item label="Kulut" name="sum_fee">
            <InputNumber
              min="0"
              max="100000"
              step="0.01"
              stringMode
              precision="2"
              disabled={(editingRow?.type || '') === 'CAPITAL'}
            />
          </Form.Item>
          <Form.Item label="Kohde" name="own">
            <Radio.Group>
              <Radio value="0">TB</Radio>
              <Radio value="1">IJ</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )




}

export default TilitysTapahtumat;

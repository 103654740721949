import React, { useState, useEffect } from 'react';
import { Input, Button, Table, Spin, Descriptions, Modal, Layout, Menu, Row, Col, Typography, Upload, Form, Checkbox, Radio, Space, Switch, Select } from 'antd';
import axios from 'axios';
import { message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { parseISO, format } from 'date-fns'
import {
  FileSearchOutlined,
  LogoutOutlined,
  DownloadOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

import jwtDecode from 'jwt-decode';

import './App.css';

import TilitysTapahtumat from './TilitysTapahtumat'
const { Option } = Select;

const { TextArea } = Input
const { Header, Content } = Layout;
const { Title } = Typography;

const backendApiUrl = process.env.REACT_APP_API_URL

message.config({
  top: 80
})

const Suoramaksut = ({ admin }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showEmptyInvoiceNum, setShowEmptyInvoiceNum] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    filterData();
  }, [data, showEmptyInvoiceNum])

  const fetchData = async () => {
    try {
      const response = await axios.get(`${backendApiUrl}/api/suoramaksut`)
      const formattedData = response.data.map(item => ({
        ...item,
        maksuPaiva: format(parseISO(item.maksuPaiva), 'd.M.yyyy'),
        aineistoPaiva: format(parseISO(item.aineistoPaiva), 'd.M.yyyy')
      }));
      setData(formattedData);
      setFilteredData(formattedData);
    } catch (error) {
      message.error({
        message: 'Datan latausvirhe',
        description: 'Datan haku palvelimelta epäonnistui.'
      });
    }
  };


  const filterData = () => {
    const filtered = data.filter(item => showEmptyInvoiceNum || item.invoiceNum);
    setFilteredData(filtered);
  };

  const handleSwitchChange = (checked) => {
    setShowEmptyInvoiceNum(checked);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    const filtered = data.filter(item => item[dataIndex].toString().toLowerCase().includes(selectedKeys[0].toLowerCase()));
    setFilteredData(filtered);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
    setFilteredData(data);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Hae ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <button
          type="button"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: '100%' }}
        >
          Hae
        </button>
        <button
          onClick={() => handleReset(clearFilters)}
          style={{ width: '100%', marginTop: 8 }}
        >
          Nollaa
        </button>
      </div>
    ),
    filterIcon: filtered => <i className="anticon" style={{ color: filtered ? '#1890ff' : undefined }}>🔍</i>,
  });

  const columns = [
    {
      title: 'Maksupäivä',
      dataIndex: 'maksuPaiva',
      key: 'maksuPaiva',
    },
    {
      title: 'Asiakasnumero',
      dataIndex: 'asiakasNumero',
      key: 'asiakasNumero',
      ...getColumnSearchProps('asiakasNumero'),
    },
    {
      title: 'Maksaja',
      dataIndex: 'maksaja',
      key: 'maksaja',
      ...getColumnSearchProps('maksaja'),
    },
    {
      title: 'Maksuviite',
      dataIndex: 'maksuviite',
      key: 'maksuviite',
    },
    {
      title: 'Laskunumero',
      dataIndex: 'invoiceNum',
      key: 'invoiceNum',
      ...getColumnSearchProps('invoiceNum'),
    },
    {
      title: 'Summa',
      dataIndex: 'summa',
      key: 'summa',
    },
    {
      title: 'Aineisto',
      dataIndex: 'aineistoPaiva',
      key: 'aineistoPaiva',
    },
  ];

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginRight: 8 }}>Näytä kaikki</span>
        <Switch checked={showEmptyInvoiceNum} onChange={handleSwitchChange} />
      </div>
      <Table columns={columns} dataSource={filteredData} />
    </>
  );

};


export { Suoramaksut }